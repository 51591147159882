import React from "react";
import Slider from "react-slick";
import Testimonial2 from "../../../assets/Images/Testimonial/Testimonial2.png";
import Testimonial3 from "../../../assets/Images/Testimonial/Testimonial3.png";
import Testimonial4 from "../../../assets/Images/Testimonial/Testimonial4.png";
import MSHubLogo from "../../../assets/Images/BrandLogo/MSHubLogo.png";
import StartupIndiaLogo from "../../../assets/Images/BrandLogo/StartupIndia.png";
import AtlassianLogo from "../../../assets/Images/BrandLogo/Atalssian.png"


function PeopleSay() {
  const settings = {
    autoplay: false,
    autoplaySpeed: 1500,
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="spacing">
        <div className="peopleSayBgColor">
          <div className="container">
            <div className="titleFont text-center">
              {/* <h3>What People Say</h3> */}
           




          
            </div>
            { <Slider {...settings}>
              <div className="container">
                <img src={MSHubLogo} alt="Microsoft Start Hub Partner" className="img-fluid mx-auto WLogo" />

                </div>

              <div className="container">

                   <img src={StartupIndiaLogo} alt="Start up India" className="img-fluid mx-auto WLogo" />
                </div>

              <div className="container">

                 <img src={AtlassianLogo} alt="Atlassian for Start up" className="img-fluid mx-auto WLogo" />
                
              </div>
            </Slider> }
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleSay;
