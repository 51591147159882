import React from "react";
import "../../../../src/theme/css/HomePage.css";
import owner from "../../../assets/Images/Banner/owner.jpg"
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <div >
        <div className="banner banner-details">
          <div className="banner-all-details">
            <div className="text-width">
              <h3 >
                AI Powered Digital Virtual Staff - Redefining Workforce for tomorrow!
              </h3>
              
              <p>
                Help enterprises eliminate friction in customer and employee journeys using AI Powered Digital Virtual Staff
              </p>
              <button className=" button">
                  <Link to="https://www.youtube.com/watch?v=9Ji1zEinM4o"> Watch Demo </Link>
                </button>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
}
