import React, { useEffect, useRef } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/DVS_transparent.png";
import BlackThemeLogo from "../../assets/Images/BrandLogo/DVS_transparent.png";
import { useState } from "react";
import { useAuth } from "../../AuthProvider";
import HumanLogo from '../../assets/Images/login/huma1.png'
import styles from '../../theme/css/Header.css'

const Header = (props) => {
  const { user, isAuthenticated, logout } = useAuth();
  const [addClass, setAddClass] = useState(" ");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogOutDropdown, setShowLogOutDropdown] = useState(false);
  const dropDownRef = useRef();

  useEffect(() => {
    // Add event listener to detect clicks on the document body
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    // Close dropdown if click occurs outside the dropdown
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowLogOutDropdown(false);
    }
  };

  const handleLogoClick = () => {
    setShowLogOutDropdown(!showLogOutDropdown);
  };

  const handleLogout = () => {
    logout();
    setShowLogOutDropdown(false);
  };
  const scrollTrigger = 20;
  console.log('TEST');
  const onToggle = () => {
    document.getElementById("myOverlay").style.display = "block";
    document.getElementById("stamenu").className = "active";
  };

  const onCloseNav = () => {
    document.getElementById("myOverlay").style.display = "none";
    document.getElementById("stamenu").className = "";
  };

  window.onscroll = function () {
    setAddClass();
    if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
      document.getElementsByTagName("nav")[0].classList.add("inverted");
    } else {
      document.getElementsByTagName("nav")[0].classList.remove("inverted");
    }
  };

  return (
    <>
      <div className={addClass}>
        <div className="header">
          <div className="sticky-top" id="stamenu">
            <Navbar expand="lg" collapseOnSelect>
              <div className="container">
                <div className="inner-header">
                  <Navbar.Brand href="/home">
                    <img src={ThemeLogo} alt="themeLogo" className="img-fluid mx-auto WLogo" />
                    <img src={BlackThemeLogo} alt="themeLogo" className="img-fluid mx-auto BLogo" />
                  </Navbar.Brand>
                  <Button
                    className="btn-navbar navbar-toggle"
                    onClick={onToggle}
                    data-toggle="collapse"
                    data-target=".nabar-ex1-collapse"
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </Button>

                  <div id="mySidenav" className="sidenav menu-vertical">
                    <div className="close-nav hidden-md hidden-lg hidden-xl ">
                      <span>Menu</span>
                      <Button className="closebtn pull-right" onClick={onCloseNav}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </div>

                    <Nav className="justify-content-center" id="myOverlay" style={{ flex: 1 }}>
                      <Nav.Link href="home">
                        <Link to="/home"> Home </Link>
                      </Nav.Link>

                      <Nav.Link href="#aboutus">
                        <Link to="/home">About Us</Link>
                      </Nav.Link>

                      <NavDropdown
                        title="Products"
                        id="collasible-nav-dropdown"
                        show={showDropdown}
                        onMouseLeave={() => setShowDropdown(false)}
                        onMouseOver={() => setShowDropdown(true)}
                      >
                        <NavDropdown.Item >
                          <Link to="/sm">S.JEEVES</Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item >
                          <Link to="/cc">LUMINO</Link>
                        </NavDropdown.Item>
                      </NavDropdown>

                     {/*
                     <Nav className="ml-auto">
                        <Nav.Link href="#signin" >
                          {user ? (
                            <span>
                              <img src={HumanLogo} alt=""/> 
                            </span>
                          ) : (
                            <span >
                              <Link to="/signin">Sign in</Link>
                            </span>
                          )}
                        </Nav.Link>
                        {user && (
                          <NavDropdown show={showLogOutDropdown} title={user.name} id="basic-nav-dropdown" ref={dropDownRef} onClick={handleLogoClick}>
                            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                          </NavDropdown>
                        )}
                      </Nav>
                     */} 
                    </Nav>
                  </div>
                  <div className="w3-overlay w3-animate-opacity"></div>
                  <button className="white-btn button">
                    <Link to="/contact"> Contact Us </Link>
                  </button>
                </div>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
