import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Icon1 from "../../../assets/Images/WeProvide/Icon1.png";
import Icon2 from "../../../assets/Images/WeProvide/Icon2.png";
import Icon3 from "../../../assets/Images/WeProvide/Icon3.png";
import Icon4 from "../../../assets/Images/WeProvide/Icon4.png";
export default function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="aboutus spacing">
          <Row className="align-items-center">
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="details-icon">
              <Row>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="weProBox">
                    <div className="weProBoxImg">
                      <img
                        className="img-fluid mx-auto"
                        alt="AboutUs"
                        src={Icon1}
                      />
                    </div>
                    <div className="weProBoxText">
                      <h6>DVS Scrum Master</h6>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="weProBox">
                    <div className="weProBoxImg">
                      <img
                        className="img-fluid mx-auto"
                        alt="AboutUs"
                        src={Icon2}
                      />
                    </div>
                    <div className="weProBoxText">
                      <h6>DVS Invest Assist</h6>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="weProBox">
                    <div className="weProBoxImg">
                      <img
                        className="img-fluid mx-auto"
                        alt="AboutUs"
                        src={Icon3}
                      />
                    </div>
                    <div className="weProBoxText">
                      <h6>DVS Career Counsellor</h6>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="weProBox">
                    <div className="weProBoxImg">
                      <img
                        className="img-fluid mx-auto"
                        alt="AboutUs"
                        src={Icon4}
                      />
                    </div>
                    <div className="weProBoxText">
                      <h6>Bespoke Roles</h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              data-aos="fade-up"
              className="details-summary"
            >
              <div className="titleFont">
                <h3>What is a Digital Virtual Staff?</h3>
                <p></p>

                {/* <h2>Using the power of LLM, API integrations manage elasticity</h2> */}
              </div>
              <p className="paragraph">
                A Digital Virtual Staff (DVS) is an AI-powered solution designed
                to assist organizations by performing various tasks and
                activities typically handled by its employees. It is
                integrated with the organization's systems and aims to automate
                mundane and administrative tasks, freeing up its employees to
                focus on more strategic activities.
              </p>
              <p className="paragraph">
                Unlike traditional bots, which may specialize in specific tasks or functions, a DVS
                is designed to take on a broader range of activities, mimicking
                the real job descriptions within the organization. The
                goal of a DVS is to increase efficiency, reduce operational
                costs, and streamline workflows within an organization by
                leveraging AI technology to handle repetitive and time-consuming
                tasks. Additionally, DVS can work around the clock supporting projects and products and ensure the tasks keep moving forward.
              </p>

             
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
