import React, { useEffect } from "react";
import "../../../theme/css/Planing.css";
import { Row, Col } from "react-bootstrap";
import OurPricing from "../HomePage/OurPricing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import Aos from "aos";
import "aos/dist/aos.css";
import OurTestimonial from "../FAQ/OurTestimonial";
import { Link } from "react-router-dom";

export default function CareerCounsellor() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="banner banner-details">
          <div className="banner-all-details">
            <div className="text-width">
              <h3>LUMINO - AI Powered Career Counsellor </h3>

              <p>Guiding Future, Anytime, Anywhere</p>
            </div>
          </div>
        </div>

        <div className="aboutus spacing">
          <button className=" button">
            <Link to="https://www.youtube.com/watch?v=9Ji1zEinM4o">
              {" "}
              Watch Demo{" "}
            </Link>
          </button>
        </div>

        <div className="titleFont spacing-1">
          <h3>About LUMINO</h3>
          <p className="paragraph">
            In India alone we are having roughly 40 million students in classes
            9th to 12th. Most of these students are either clueless about their
            future career paths go for well-established streams primarily due to
            lack of awareness about the diverse career opportunities that exist
            beyond traditional paths. India lacks career counsellors who can
            guide these young minds and help shape the future. Whether you are a
            parent seeking advice for your child or a student seeking advice for
            yourself, LUMINO is here to help you!
          </p>
        </div>

        <div className="titleFont spacing-1">
          <h3>Why LUMINO</h3>

          <ul>
            <li>
              {" "}
              On-Demand Counseling - No need to wait for prior appointments. Do
              it at your place at your time!
            </li>
            <li>
              {" "}
              Affordable Career Guidance - No need to spend a fortune seeking
              career guidance!
            </li>
            <li>
              {" "}
              No limits - Your career advice is not limited to the knowledge of
              human career counsellors!
            </li>
            <li>
              {" "}
              Conversational Interface - Say goodbye to tedious forms and
              experience a more interactive way of getting the support you need!
            </li>
          </ul>
        </div>

        <div className="titleFont spacing-1">
          <h3>Our Core Services</h3>
          <ul>
            <li>
              {" "}
              Student Counseling - Interact with our AI Powered Career
              Counsellor anywhere, anytime
            </li>

            <li>
              {" "}
              Parent Counseling - Parents inputs are crucial in shaping the
              career of their children. Connect with our AI Powered Career
              Counsellor to get the best advice.
            </li>
            <li>
              {" "}
              Instant Personalized Report - Generate your career guidance
              summary instantly after the session is completed
            </li>
            <li>
              {" "}
              Consulting - Connect with real-life parents and gain valuable
              insights into their challenges and journeys. Learn from their
              experiences to better navigate your own career path
            </li>
            <li>
              {" "}
              Connect with our Partner Career Counselling experts - Need more
              personalized guidance? Connect with our partner career counsellors
              for follow up session
            </li>
          </ul>
        </div>

        <div className="aboutus spacing">
          <button className=" button">
            <Link to="https://cc.dvs.solutions">
              {" "}
              Start Career Counseling Journey{" "}
            </Link>
          </button>
        </div>
      </div>
    </>
  );
}
